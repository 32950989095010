import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faChevronLeft, faChevronRight, faSpinner, faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-brands-svg-icons';

import './main.scss';

library.add(faBars, faSpinner, faChevronLeft, faChevronRight, faPhone, faEnvelope, faClock)

const LandingPageController = lazy(() => import('./components/LandingPage/LandingPageController'));
const ContactPageController = lazy(() => import('./components/ContactPage/ContactPageController'));
const AboutPageController = lazy(() => import('./components/AboutPage/AboutPageController'));
const DesignPageController = lazy(() => import('./components/DesignPage/DesignPageController'));
const BuildPageController = lazy(() => import('./components/BuildPage/BuildPageController'));
const SurveyPageController = lazy(() => import('./components/SurveyPage/SurveyPageController'));
const FAQsPageController = lazy(() => import('./components/FAQsPage/FAQsPageController'));
// const EcoSaversPageController = lazy(() => import('./components/EcoSaversPage/EcoSaversPageController'));
// const RestorationController = lazy(() => import('./components/RestorationPage/RestorationPageController'));
const PropertyInvestment = lazy(() => import('./components/PropertyInvestmentPage/PropertyInvestmentController'));
const noMatchPage = lazy(() => import('./components/Base/NoMatchPage'));

const App = () => {
    return (
          <div className="App">
            <BrowserRouter>
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route path="/" exact component={LandingPageController} />
                        <Route path="/Home" exact component={LandingPageController} />
                        <Route path="/About" exact component={AboutPageController} />
                        <Route path="/Property-Investment" exact component={PropertyInvestment} />
                        <Route path="/Design" exact component={DesignPageController} />
                        <Route path="/Build" exact component={BuildPageController} />
                        <Route path="/Survey" exact component={SurveyPageController} />
                        <Route path="/Contact" exact component={ContactPageController} />
                        {/* <Route path="/Restoration-and-Conservation" exact component={RestorationController} />
                        <Route path="/Eco-Savers" exact component={EcoSaversPageController} /> */}
                        <Route path="/FAQs" exact component={FAQsPageController} />
                        
                        <Route component={noMatchPage} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
          </div>
    );
}

export default App;